import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { GlobalSet } from '../../global/GlobalProvider';
import { Button, Modal } from 'react-bootstrap';
import axios from "axios";
import swal from "sweetalert";
import { useTranslation } from 'react-i18next';

const BasicDatatable = () => {
  const { t } = useTranslation();
  const { CurrencySymbol } = GlobalSet();
  const history = useHistory();
  const user_type = localStorage.getItem('user_type');

  if (user_type !== "Sub-Agent") {
    history.push('/dashboard');
    swal("Warning", "Permission Not Granted!", "warning");
  }

  /*.....income data......*/
  const [income, setIncome] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null); // State to store selected row data
  const [showModal, setShowModal] = useState(false); // State to control modal visibility

  const fetchData = () => {
    const selectedCurrency = localStorage.getItem('selectedCurrency');
    const auth_token = localStorage.getItem('auth_token');
    const data = {
      key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
      currency: selectedCurrency,
    };
    if (auth_token !== null) {
      axios.post('/manage-user-withdrawal', data)
        .then(response => {
          setIncome(response.data.data);
          console.log(response.data.data);
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Function to open the modal and set the selected row
  const handleOpenModal = (row) => {
    setSelectedRow(row);
    setShowModal(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedRow(null);
  };

  // Function to handle Cancel API call
  const handleCancel = () => {
    const data = {
      key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
      id: selectedRow.id, // Pass the selected row's id
    };

    axios.post('/cancel-withdrawal', data)
      .then(response => {

        if(response.data.status === 'success')
        {
           swal("Success",response.data.message,"success");
        }
        else if(response.data.status === 'fail')
        {
          swal("Warning",response.data.message,"warning");
        }else{
          swal("Warning","Error Withdrawal","warning");
        }

        handleCloseModal(); // Close the modal after action
        fetchData(); // Refresh data after action
      })
      .catch(error => {
        console.log('Error canceling withdrawal:', error);
        swal("Error", "Failed to cancel withdrawal", "error");
      });
  };

  // Function to handle Accept API call
  const handleAccept = () => {
    const data = {
      key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
      id: selectedRow.id, // Pass the selected row's id
    };

    axios.post('/paid-withdrawal', data)
      .then(response => {
        if(response.data.status === 'success')
        {
           swal("Success",response.data.message,"success");
        }
        else if(response.data.status === 'fail')
        {
          swal("Warning",response.data.message,"warning");
        }else{
          swal("Warning","Error Withdrawal","warning");
        }
        handleCloseModal(); // Close the modal after action
        fetchData(); // Refresh data after action
      })
      .catch(error => {
        console.log('Error marking as paid:', error);
        swal("Error", "Failed to mark withdrawal as paid", "error");
      });
  };

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Users Withdrawal</h4>
        </div>
        <div className="card-header">
          <div className="table-responsive">
            <div id="job_data" className="dataTables_wrapper">
              <table
                className="display w-100 dataTable"
                id="example5"
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th style={{ width: "177px" }}>SL</th>
                    <th style={{ width: "177px" }}>Username</th>
                    <th style={{ width: "177px" }}>Amount</th>
                    <th style={{ width: "177px" }}>Payment Address</th>
                    <th style={{ width: "278px" }}>Gateway</th>
                    <th style={{ width: "128px" }}>Create Time</th>
                    <th style={{ width: "128px" }}>Status</th>
                    <th style={{ width: "128px" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {income.map((incomerow, index) => (
                    <tr key={incomerow.id} className="odd" role="row">
                      <td>{index + 1}</td>
                      <td>{incomerow.user_Id_d}</td>
                      <td>{CurrencySymbol + parseFloat(incomerow.amount).toFixed(4)}</td>
                      <td>{incomerow.transaction_number}</td>
                      <td>{incomerow.method}</td>
                      <td>{incomerow.date}</td>
                      <td>{ incomerow.status==="1" ? " Success"  : incomerow.status==="0" ? " failed"  : incomerow.status==="Pending" ? " Pending"  : incomerow.status==="2" ? " Cancel" : incomerow.status }</td>
                      <td>
                        <Link onClick={() => handleOpenModal(incomerow)}>
                          <i style={{ fontSize: '20px' }} className="fa fa-info-circle" aria-hidden="true"></i>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for displaying row details */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedRow?.user_Id_d} - Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedRow && (
            <div>
              <p><strong>Amount:</strong> {CurrencySymbol + parseFloat(selectedRow.amount).toFixed(4)}</p>
              <p><strong>Payment Address:</strong> {selectedRow.transaction_number}</p>
              <p><strong>Gateway:</strong> {selectedRow.method}</p>
              <p><strong>Create Time:</strong> {selectedRow.date}</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="me-2 btn btn-primary" onClick={handleCancel}>Cancel</button>
          <button type="button" className="me-2 btn btn-secondary" onClick={handleAccept}>Accept</button>
          <Button variant="btn btn-dark" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BasicDatatable;
