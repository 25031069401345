import React, { useState, useRef, useEffect , useReducer} from "react";
import { Button, Dropdown, Modal, Tab, Nav } from "react-bootstrap";
import { GlobalSet } from '../../global/GlobalProvider';
import { QRCodeCanvas } from "qrcode.react";
import { Link, useParams, useHistory } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";
//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';


//** Import Image */
const BasicDatatable = () => {
  const { t } = useTranslation();
  const { username } = useParams();
  const history = useHistory();
  const selectedCurrency = localStorage.getItem('selectedCurrency');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { CurrencySymbol } = GlobalSet();
  const [data, setData] = useState(
    document.querySelectorAll("#job_data tbody tr")
  );

  const user_type = localStorage.getItem('user_type');
  if (!["Sub-Agent"].includes(user_type)) {
   history.push('/dashboard');
   swal("Warning","Permission Not Granted !","warning");
  }

 const [seAff, setAff] = useState([]);

  const LoadDataRe = () => {
      const auth_token = localStorage.getItem('auth_token');
        const data = {
            username: username,
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
        }
      if (auth_token !== null) {
         axios.post('/ag-view-user',data)
         .then(response=>{
           setAff(response.data.data);
           })
           .catch(error=>{
            swal("error", "Sorry, Not valid this link", "error");
            history.push('/dashboard');
           })
      }
    }
  useEffect(() => {
      LoadDataRe();
  }, []);


  const handleReloadData = () => {
      LoadDataRe();
  };


  const [showForm, setShowForm] = useState(false);
  const [depositInput, setDepositInput] = useState({
    amount: '',
    password: '',
    error_list: [],
  });

  const handleInput = (e) => {
    e.persist();
    setDepositInput({ ...depositInput, [e.target.name]: e.target.value });
  }

  const promocodeSubmit = (e) => {
    e.preventDefault();
    const auth_token = localStorage.getItem('auth_token');
    const data = {
      key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
      username: username,
      amount: depositInput.amount,
      password: depositInput.password,
      currency: selectedCurrency,
    }

    if (auth_token !== null  && !loading) {
      setLoading(true);
      axios.post(`/transfer-ag-usre-balance`, data).then(res => {
        setLoading(false);
        LoadDataRe();
        if (res.data.status === 'success') {
          swal("Success", res.data.message, "success");

          setDepositInput({
            ...depositInput,
            amount: '',
            password: '',
            error_list: []
          });
        } else if (res.data.status === 'fail') {
          swal("Warning", res.data.message, "warning");
        } else {
          setDepositInput({ ...depositInput, error_list: res.data.validation_error });
        }
        setLoading(false);
      });
    }
  }

  const handleShowButtonClick = () => {
    setShowForm(true);
  };

  const handleHideButtonClick = () => {
    setShowForm(false);
  };

  return (
    <div className="col-12">
      <div className="card mb-0">
        <div className="card-header">
          <h4 className="card-title"> Agent Details</h4>
          {!showForm ? (
            <button onClick={handleShowButtonClick} type="button" className="me-2 btn btn-warning btn-rounded">
              <span className="btn-icon-start text-info"><i className="fa fa-plus color-info"></i></span>Add Balance
            </button>
          ) : (
            <button onClick={handleHideButtonClick} type="button" className="me-2 btn btn-warning btn-rounded">
              <span className="btn-icon-start text-info"><i className="fa fa-plus color-info"></i></span>Add Balance
            </button>
          )}
        </div>
        </div>

        <div className="card-body">             
        {!showForm ? (
            ""
          ) : (
            <div>
              <div className="col-xl-12 col-lg-12 mb-3">
                <div className="basic-form">
                  <form onSubmit={promocodeSubmit} className="needs-validation" noValidate="">
                    <div className="row">

                      <div className="col-sm-4">
                        <div className="input-group mb-3 input-warning">
                          <input
                            type="number"
                            className="form-control"
                            placeholder={`Enter ${selectedCurrency} Amount`}
                            id="amount"
                            required
                            name="amount"
                            onChange={handleInput}
                            value={depositInput.amount}
                          />
                          <span className="input-group-text">{CurrencySymbol}</span>
                        </div>
                        <span className="text-danger">{depositInput.error_list.amount}</span>
                      </div>
                    


                      <div className="col-sm-5">
                       <div className="input-group transparent-append mb-2">
                          <input
                            type={`${showPassword ? "text" : "password"}`}
                            className="form-control"
                            id="password"
                            placeholder="Password"
                            name="password"
                            onChange={handleInput} 
                            value={depositInput.password}
                          />
                          <div
                            className="input-group-text "
                            onClick={() => setShowPassword(!showPassword)}
                          >
                          {" "}
                          {showPassword === false ? (<i className="fa fa-eye-slash" />) : (<i className="fa fa-eye" />)}
                        
                          </div>
                          <div
                            id="val-username1-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                          </div> 
                           <span className="text-primary">{depositInput.error_list.password}</span>
                        </div>
                     </div> 

                      <div className="col-sm-3 mt-2 mt-sm-0">
                        <button type="submit" className="btn btn-warning mb-2">
                            {loading ? 'Submitting...' : 'Send Balance'}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
     
            <div className="col-xl-12">
              <div className="row">
                <div className="col-xl-3 col-sm-6">
                  <div className="card booking">
                    <div className="card-body">
                      <div className="booking-status d-flex align-items-center">
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="20" viewBox="0 0 28 20">
                            <path  d="M27,14V7a1,1,0,0,0-1-1H6A1,1,0,0,0,5,7v7a3,3,0,0,0-3,3v8a1,1,0,0,0,2,0V24H28v1a1,1,0,0,0,2,0V17A3,3,0,0,0,27,14ZM7,8H25v6H24V12a2,2,0,0,0-2-2H19a2,2,0,0,0-2,2v2H15V12a2,2,0,0,0-2-2H10a2,2,0,0,0-2,2v2H7Zm12,6V12h3v2Zm-9,0V12h3v2ZM4,17a1,1,0,0,1,1-1H27a1,1,0,0,1,1,1v5H4Z" transform="translate(-2 -6)" fill="var(--primary)"/>
                          </svg>
                        </span>
                        <div className="ms-4">
                          <h4 className="mb-0 font-w600">{CurrencySymbol +" "+ parseFloat(parseFloat(seAff.balance).toFixed(4)) }</h4>
                          <p className="mb-0 text-nowrap">{t('pro_pg_curr_bal')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="card booking">
                    <div className="card-body">
                      <div className="booking-status d-flex align-items-center">
                        <span>

                          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="20" viewBox="0 0 24 24" fill="none" stroke="#e50505" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="4" y1="21" x2="4" y2="14"></line><line x1="4" y1="10" x2="4" y2="3"></line><line x1="12" y1="21" x2="12" y2="12"></line><line x1="12" y1="8" x2="12" y2="3"></line><line x1="20" y1="21" x2="20" y2="16"></line><line x1="20" y1="12" x2="20" y2="3"></line><line x1="1" y1="14" x2="7" y2="14"></line><line x1="9" y1="8" x2="15" y2="8"></line><line x1="17" y1="16" x2="23" y2="16"></line></svg>
                                                    
                        </span>
                        <div className="ms-4">
                          <h4 className="mb-0 font-w600">{CurrencySymbol +" "+ "0"} </h4>
                          <p className="mb-0 text-nowrap">{t('pro_pg_bon_inc')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="card booking">
                    <div className="card-body">
                      <div className="booking-status d-flex align-items-center">
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="20" viewBox="0 0 28 20">
                            <path  d="M27,14V7a1,1,0,0,0-1-1H6A1,1,0,0,0,5,7v7a3,3,0,0,0-3,3v8a1,1,0,0,0,2,0V24H28v1a1,1,0,0,0,2,0V17A3,3,0,0,0,27,14ZM7,8H25v6H24V12a2,2,0,0,0-2-2H19a2,2,0,0,0-2,2v2H15V12a2,2,0,0,0-2-2H10a2,2,0,0,0-2,2v2H7Zm12,6V12h3v2Zm-9,0V12h3v2ZM4,17a1,1,0,0,1,1-1H27a1,1,0,0,1,1,1v5H4Z" transform="translate(-2 -6)" fill="var(--primary)"/>
                          </svg>
                        </span>
                        <div className="ms-4">
                          <h4 className="mb-0 font-w600">{CurrencySymbol +" "+ parseFloat(parseFloat(seAff.total_deposit).toFixed(4)) }</h4>
                          <p className="mb-0 text-nowrap ">{t('pro_pg_tot_dep')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="card booking">
                    <div className="card-body">
                      <div className="booking-status d-flex align-items-center">
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                            <path  data-name="Path 1957" d="M129.035,178.842v2.8a5.6,5.6,0,0,0,5.6,5.6h14a5.6,5.6,0,0,0,5.6-5.6v-16.8a5.6,5.6,0,0,0-5.6-5.6h-14a5.6,5.6,0,0,0-5.6,5.6v2.8a1.4,1.4,0,0,0,2.8,0v-2.8a2.8,2.8,0,0,1,2.8-2.8h14a2.8,2.8,0,0,1,2.8,2.8v16.8a2.8,2.8,0,0,1-2.8,2.8h-14a2.8,2.8,0,0,1-2.8-2.8v-2.8a1.4,1.4,0,0,0-2.8,0Zm10.62-7-1.81-1.809a1.4,1.4,0,1,1,1.98-1.981l4.2,4.2a1.4,1.4,0,0,1,0,1.981l-4.2,4.2a1.4,1.4,0,1,1-1.98-1.981l1.81-1.81h-12.02a1.4,1.4,0,1,1,0-2.8Z" transform="translate(-126.235 -159.242)" fill="var(--primary)" fill-rule="evenodd"/>
                          </svg>
                        </span>
                        <div className="ms-4">
                          <h4 className="mb-0 font-w600">{CurrencySymbol +" "+ parseFloat(parseFloat(seAff.total_Income).toFixed(4)) }</h4>
                          <p className="mb-0">{t('pro_pg_tot_inc')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="card booking">
                    <div className="card-body">
                      <div className="booking-status d-flex align-items-center">
                        <span>
                          <svg id="_009-log-out" data-name="009-log-out" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                            <path  data-name="Path 1957" d="M151.435,178.842v2.8a5.6,5.6,0,0,1-5.6,5.6h-14a5.6,5.6,0,0,1-5.6-5.6v-16.8a5.6,5.6,0,0,1,5.6-5.6h14a5.6,5.6,0,0,1,5.6,5.6v2.8a1.4,1.4,0,0,1-2.8,0v-2.8a2.8,2.8,0,0,0-2.8-2.8h-14a2.8,2.8,0,0,0-2.8,2.8v16.8a2.8,2.8,0,0,0,2.8,2.8h14a2.8,2.8,0,0,0,2.8-2.8v-2.8a1.4,1.4,0,0,1,2.8,0Zm-10.62-7,1.81-1.809a1.4,1.4,0,1,0-1.98-1.981l-4.2,4.2a1.4,1.4,0,0,0,0,1.981l4.2,4.2a1.4,1.4,0,1,0,1.98-1.981l-1.81-1.81h12.02a1.4,1.4,0,1,0,0-2.8Z" transform="translate(-126.235 -159.242)" fill="var(--primary)" fill-rule="evenodd"/>
                          </svg>

                        </span>
                        <div className="ms-4">
                          <h4 className="mb-0 font-w600">{CurrencySymbol +" "+ parseFloat(parseFloat(seAff.total_withdraw).toFixed(4)) }</h4>
                          <p className="mb-0">{t('pro_pg_tot_wit')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div className="card">
               <div className="card-body">
                  <div className="custom-tab-1 mt-2">
                    <Tab.Container defaultActiveKey='About'>          
                      <Nav as='ul' className="nav nav-tabs mb-4">
                        <Nav.Item as='li'i className="nav-item">
                          <Nav.Link to="#about-me"  eventKey='About'>{t('pro_pg_ab_me')}</Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>

                        <Tab.Pane id="about-me" eventKey='About'>
                          <div className="profile-personal-info">
                            <h4 className="text-primary mb-4">
                              Personal Information
                            </h4>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500"> {t('pro_pg_name')}<span className="pull-right">:</span></h5>
                              </div>
                              <div className="col-9">
                                <span>{seAff.name}</span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500"> {t('pro_pg_username')}<span className="pull-right">:</span></h5>
                              </div>
                              <div className="col-9">
                                <span>{seAff.username}</span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">{t('pro_pg_mob_num')}<span className="pull-right">:</span></h5>
                              </div>
                              <div className="col-9">
                                <span>{seAff.mobile_No}</span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">{t('pro_pg_email')}<span className="pull-right">:</span></h5>
                              </div>
                              <div className="col-9">
                                <span>{seAff.email}</span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">{t('pro_pg_age')}<span className="pull-right">:</span></h5>
                              </div>
                              <div className="col-9">
                                <span>..</span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">  {t('pro_pg_location')}<span className="pull-right">:</span></h5>
                              </div>
                              <div className="col-9">
                                <span>..</span>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>  
                    </Tab.Container>    
                  </div>
              </div>
            </div>



        </div>
      </div>
  );
};

export default BasicDatatable;
